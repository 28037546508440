<template>
    <v-row class="d-flex justify-center">
        <v-col cols="12" sm="8" md="10" lg="6" xl="4">
            <v-form ref="form">
                <v-card class="xs-margin xs-padding sm-padding card-shadow bg-white">
                    <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">Create New Password</h1>
                    <p class="text-light font-size-root text-center font-weight-thin mb-8">Add a password for your account</p>
                    <v-card-text class="font-size-root">
                        <label for="password" class="label-color font-weight-600 subtitle-2 mb-2 d-block">New Password</label>
                        <v-text-field
                            id="password"
                            v-model="newPassword"
                            :rules="[rules.required, rules.password]"
                            autocomplete="off"
                            hide-details="auto"
                            validate-on-blur
                            outlined
                            class="input-style font-size-input text-color-gray mt-0 mb-4"
                            placeholder="********"
                            :type="showPassword ? 'text' : 'password'">
                            <template slot="append">
                                <feather :type="showPassword ? 'eye' : 'eye-off'" size=".875rem" stroke-width="1.5" color="#adb5bd" @click="showPassword = !showPassword" class="eye"> </feather>
                            </template>
                        </v-text-field>

                        <v-btn @click="setPassword" elevation="0" block height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm my-6" color="#6F70FF">Set Password</v-btn>
                    </v-card-text>
                </v-card>
            </v-form>

            <div class="d-flex justify-center mt-4">
                <small class="text-light"
                    >Back to
                    <router-link to="login" class="text-decoration-none">
                        <span class="text-primary">Login</span>
                    </router-link>
                </small>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import { mapActions } from 'vuex';
import { authManagement } from '@/store/api';

export default {
    name: 'setPassword',
    data() {
        return {
            showPassword: false,
            newPassword: '',
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required',
                password: (value) => {

                    let atLeast10Chars = value.length >= 10;
                    let matchRestPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))^[^ ]+$/g;
                    let matchRest = matchRestPattern.test(value);

                    return (atLeast10Chars && matchRest) || 'Password must be at least 10 characters, with lower- and uppercase letters, at least one number, and a special character';

                    // const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
                    // return pattern.test(value) || 'Password must be at least 10 characters, with lower- and uppercase letters, at least one number, and a special character';
                }
            }
        };
    },
    methods: {
        async setPassword() {
            if (this.$refs.form.validate()) {
                try {
                    await authManagement.create({
                        action: 'verifySignupSetPasswordLong',
                        value: {
                            token: this.token,
                            password: this.newPassword
                        }
                    });

                    this.$router.push({ name: 'login' });
                    this.showSuccess('Password successfully Set!');
                } catch (error) {
                    this.showError(error);
                }
            }
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        token() {
            return (this.$route.query && this.$route.query.token) || '';
        }
    }
};
</script>

<style lang="scss">
.eye {
    &:hover {
        cursor: pointer;
    }
}

@media screen and (max-width: 599px) {
    .xs-margin {
        margin: 0 10px;
    }

    .xs-padding {
        padding: 10px;
    }
}

@media screen and (min-width: 600px) {
    .sm-padding {
        padding: 40px;
    }
}
</style>
