<template>
	<v-row class="d-flex justify-center">
		<v-col cols="12" md="4">
			<v-card class="text-center error-page pa-3">
				<v-img :src="illustration" max-height="400" contain />
				<div class="mt-10 mb-6">Sorry this page is not allowed for mobile viewing.</div>
				<v-btn @click="$router.go(-1)" elevation="0"  height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm" color="#6F70FF"> Back </v-btn>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>

export default {
    data() {
        return {
            illustration: require("@/assets/img/illustrations/undraw_mobile_development_re_wwsn.svg")
        };
    }
};
</script>

<style>
.error-page {
  max-width: 500px;
}
</style>
